<template>
  <div>
      <div v-if="item.ivans_status === 'pending'">
      Pending
      </div>

      <div v-else-if="item.ivans_status === 'received'">
      Downloaded
      </div>

      <div v-else>
      Manual
      </div>
  </div>
</template>

<script>
import {
  mdiAlertCircleOutline, mdiCheckCircleOutline,
  mdiProgressCheck,
  mdiProgressClose,
  mdiProgressDownload
} from "@mdi/js";

export default {
  props: ['item'],
  data() {
    return {
      icons: {
        mdiAlertCircleOutline,
        mdiProgressDownload,
        mdiProgressCheck,
        mdiProgressClose,
        mdiCheckCircleOutline
      },
    }
  }
}
</script>

<style scoped>

</style>
