<template>
  <div>
    <div v-if="canEdit && ['NBS', 'RWL'].includes(item.type) && showPlanSelector(item)">
      <div v-if="commissionSetup.type === 'single'">
        <commission-single-plan-selector
          :key="item.id"
          :item="item"
          :showApproveFieldOutlines="showApproveFieldOutlines"
          @setToDefault="refreshCommissionStatement"
        />
      </div>
      <div v-else>
        <span v-if="isFinalized">{{ item.plan.attributes.name }}</span>
        <commission-plan-selector
          :key="item.id"
          :item="item"
          :showApproveFieldOutlines="showApproveFieldOutlines"
          v-else
        />
      </div>
    </div>
    <finalized-plan-display :item="item" v-if="!canEdit" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {},
  props: ['item'],
  methods: {},
  computed: {},
}
</script>

<style scoped></style>
