<template>
  <div>
    <div id="tablePanel">
      <producer-overview-table :loading="loading.statement" />
    </div>
    <div id="subTotalPanel">
      <subtotal-panel />
    </div>
    <div id="totalPanel">
      <total-panel />
    </div>
  </div>
</template>

<script>
import ProducerOverviewTable from '@/pages/commissions/commission-overview/pages/producer/ProducerOverviewTable'
import BonusPanel from '@/pages/commissions/commission-overview/pages/producer/BonusPanel'
import SubtotalPanel from '@/pages/commissions/commission-overview/pages/producer/SubtotalPanel'
import TotalPanel from '@/pages/commissions/commission-overview/pages/producer/TotalPanel'
import { mapActions, mapGetters } from 'vuex'

export default {
  props: ['activeStep'],
  components: {
    ProducerOverviewTable,
    SubtotalPanel,
    TotalPanel,
    BonusPanel,
  },
  data() {
    return {
      icons: {},
      loading: {
        download: false,
        statement: true,
      },
    }
  },
  methods: {
    ...mapActions({
      loadStatement: 'commissions/producerStatement/loadStatement',
    }),
    toMonthName(monthNumber) {
      const date = new Date()

      date.setFullYear(2023,parseInt(monthNumber) - 1,1);

      return date.toLocaleString('en-US', {
        month: 'long',
      })
    },
  },
  mounted() {
    this.loadStatement({
      producerId: this.$route.params.producer,
      date: this.date,
    }).then(() => {
      this.loading.statement = false
    })
  },
  computed: {
    ...mapGetters({
      producers: 'commissions/producers/getProducers',
      activeDate: 'commissions/producerStatement/getActiveDate',
      activeUser: 'commissions/producerStatement/getActiveUser',
    }),
    date() {
      return this.$route.params.year + '-' + this.$route.params.month
    },
    year() {
      return this.$route.params.year
    },
    month() {
      return this.toMonthName(this.$route.params.month)
    },
  },
  watch: {
    '$route.params': {
      handler() {
        this.loadStatement({
          producerId: this.$route.params.producer,
          date: this.date,
        })
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss" scoped>
#statementContentHeaderContainer {
  background: #fff;
  padding: 24px 32px 16px 32px;
  margin-bottom: 2px;
}

#filterPanel {
  margin-top: 32px;
}

#tablePanel {
  margin-bottom: 2px;
}

#bonusPanel,
#totalPanel,
#subTotalPanel {
  background: #fff;
}

#subTotalPanel {
  padding: 24px 32px 0px 0px;
}

#bonusPanel {
  padding: 8px 32px 16px 18px;
}

#totalPanel {
  padding: 16px 32px 16px 32px;
}

.filterSelectTitle {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #3a3541;
  letter-spacing: 0.5px;
}

.dateText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: #3a3541;
}
</style>
