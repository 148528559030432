<template>
  <div>
    <span>{{ item.type }}</span>
  </div>
</template>

<script>
export default {
  components: {},
  props: ['item'],
  methods: {},
}
</script>

<style scoped></style>
