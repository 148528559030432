import { render, staticRenderFns } from "./CommissionAgentPayColumn.vue?vue&type=template&id=690017fb&scoped=true&"
import script from "./CommissionAgentPayColumn.vue?vue&type=script&lang=js&"
export * from "./CommissionAgentPayColumn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "690017fb",
  null
  
)

export default component.exports