<template>
  <div class="text-right">
    <div>Subtotal: ${{ subtotal ? subtotal : '0.00' }}</div>

    <div class="mt-2" v-if="bonuses > 0">Total Bonus: ${{ bonuses ? bonuses.toFixed(2) : '0.00' }}</div>

    <div class="mt-2" v-if="fees > 0">Total Deductions: -${{ fees ? fees.toFixed(2) : '0.00' }}</div>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      subtotal: 'commissions/producerStatement/getSubtotal',
      bonusesAndFees: 'commissions/producerStatement/getBonusesAndFees',
    }),
    bonuses() {
      return _.sumBy(this.bonusesAndFees, bonus => {
        if (parseFloat(bonus.value) >= 0) {
          return parseFloat(bonus.value)
        }

        return 0
      })
    },
    fees() {
      return _.sumBy(this.bonusesAndFees, bonus => {
        if (parseFloat(bonus.value) < 0) {
          return Math.abs(parseFloat(bonus.value))
        }

        return 0
      })
    },
  },
}
</script>

<style></style>
