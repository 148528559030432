<template>
  <div>
    <span>
      <span v-if="item.isNegative" class="red--text">{{ item.agency_commissions }}</span>
      <span v-else>{{ item.agency_commissions }}</span>
    </span>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: ['item'],
  data() {
    return {

    }
  },
  methods: {

  },
  mounted() {

  },
  watch: {

  },
}
</script>

<style scoped></style>
