<template>
  <div>
    {{ item.insured }}
  </div>
</template>

<script>
export default {
  props: ['item'],
}
</script>

<style scoped></style>
