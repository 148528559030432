<template>
  <div>
    {{ item.agent_pay_rate }}
  </div>
</template>

<script>
export default {
  props: ['item'],
  mixins: [],
  data() {
    return {}
  },
  methods: {},
  mounted() {},
  watch: {},
  computed: {

  },
}
</script>

<style scoped></style>
