<template>
  <div class="text-right">
      <span id="totalText">
            Commission Total: <span v-if="total < 0">-</span>${{ total ? Math.abs(total).toFixed(2) : '0.00' }}
      </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      total: 'commissions/producerStatement/getTotal',
    }),
  },
}
</script>

<style lang="scss" scoped>
#totalText {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #3a3541;
}
</style>
