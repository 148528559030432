<template>
  <div>
    <div v-for="bonus in bonusesAndFees" :key="bonus.id" class="text-right">
      <strong>{{ bonus.label }}:</strong> ${{ bonus.value }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      bonusesAndFees: 'commissions/producerStatement/getBonusesAndFees',
    }),
  },
}
</script>

<style></style>
