<template>
  <div>
    {{ item.effective_date }}
  </div>
</template>

<script>
export default {
  props: ['item']
}
</script>

<style scoped>

</style>
