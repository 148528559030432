<template>
  <div class="d-flex">
    {{ item.agent_pay }}
  </div>
</template>

<script>
export default {
  props: ['item'],
  mixins: [],
  data() {
    return {

    }
  },
  methods: {

  },
  mounted() {

  },
  watch: {

  },
  computed: {

  },
}
</script>

<style scoped></style>
